.waiting-times-content
{
    text-align: center;
    max-width: 58rem;
    margin: 3rem auto 4rem;
    font-weight: 500;

    p
    {
        @extend %base-text-size;
    }

    @include media-breakpoint-down(md)
    {
        margin: 2.5rem auto 3.5rem;
    }
}

.waiting-times-faq-link > a
{
    font-weight: bold;
    color: $sel-color-6;
    text-decoration: none;

    > i
    {
        padding-right: .333rem;
        will-change: transform;
        transition: transform ease-in-out .333s;
    }

    > span
    {
        text-decoration: underline;
    }

    &:hover
    {
        > i
        {
            transform: translateX(.333rem);
        }
    }
}

.waiting-times-form
{
    @extend %sel-form;
    margin-bottom: 3.75rem;
}

.table-waiting-times
{
    @extend .table;
    margin: 0;
    background-color: $sel-color-7-fade;


    thead
    {
        background-color: $colorAccent;
        color: $colorInverse;

        th
        {
            vertical-align: middle;
            border-bottom: 0;
            line-height: 1.333;
            padding-left: 1rem;
            padding-right: 1rem;
        }

        th:first-child
        {
            @extend %base-text-size;

            @include media-breakpoint-down(md)
            {
                min-width: 200px;
            }
        }

        th:not(:first-child)
        {
            min-width: 180px;
            width: 14%;
            text-align: center;
            cursor: pointer;
        }

        th:last-child
        {
            width: 28%;
        }
    }

    tbody
    {
        border-top: 0 !important;

        th, td
        {
            border-bottom-color: $colorInverse;
            border-bottom-width: 2px;
            padding: 1rem;
        }

        th
        {
            font-weight: normal;
        }

        td
        {
            text-align: center;
            vertical-align: middle;
        }

        td:nth-child(2)
        {
            background-color: rgba($sel-color-6, 0.7);
        }

        td:nth-child(3)
        {
            background-color: rgba($sel-color-6, 0.25);
        }
    }
}

.waiting-graph-wrapper
{
    /*
    background-color: $sel-color-7-fade;
    padding: 3rem 9%;
    */

    > h3
    {
        @extend %base-text-size;
        text-align: center;
        color: $colorAccent;
        margin: 0 0 1.875rem;
    }

    > footer
    {
        /*
        margin: 3rem -12.5% -3rem;
        padding: 1.25rem 12.5% 0;
        background-color: $colorInverse;
        */
        text-align: center;

        > p
        {
            font-size: .75rem;
            line-height: 1.25rem;
        }
    }
    /*
    @include media-breakpoint-down(md)
    {
        margin: 5rem 0;
    }
    */
}

@include media-breakpoint-down(sm)
{
    .waiting-graph-size-out
    {
        max-width: 100%;
        overflow-y: auto;

        .waiting-graph-size-in
        {
            width: 40rem;
        }
    }

    .waiting-graph-size-out,
    .table-responsive
    {
        &::-webkit-scrollbar
        {
            display: none;
        }
    }
}

@include media-breakpoint-up(sm)
{
    .waiting-graph-size-out .scrollbar-track
    {
        display: none !important;
    }
}

.waiting-times-updated
{
    font-size: .75rem;
    line-height: 1;
    margin: 1em 0 1rem;
    text-align: right;

    @include media-breakpoint-up(lg)
    {
        margin-top: -1.75rem;
    }
}

#health-professionals-modal
{
    .btn
    {
        font-weight: bold;
        font-size: 1rem;
        padding-left: 1rem;
        padding-right: 1rem;
    }
}
