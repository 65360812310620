.travel-times-table
{
    @extend .table;
    margin: 0;
    background-color: $colorInverse;
    table-layout: fixed;

    thead
    {
        background-color: $colorAccent;
        color: $colorInverse;

        th
        {
            vertical-align: middle;
            border-bottom: 0;
            line-height: 1.333;
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }

    tbody
    {
        border-top: 0 !important;

        th, td
        {
            border-bottom-color: $sel-color-7-fade;
            border-bottom-width: 2px;
            padding: 1rem;
            vertical-align: middle;
        }

        th
        {
            font-weight: normal;
        }

        td
        {
            &.tt-address
            {
                background-color: $sel-color-8;
            }

            &.tt-time
            {
                text-align: center;
            }
        }
    }

    .tt-type-selector
    {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        gap: .75rem;

        .tt-type-radio
        {
            cursor: pointer;

            > input
            {
                display: none;

                &:checked + svg
                {
                    fill: $colorAlternate;
                }
            }

            > svg
            {
                height: 32px;
                fill: $colorInverse;
            }
        }
    }
}

.travel-time-form
{
    @extend %sel-form;
    margin-bottom: 2rem;
}
