body
{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    font-family: $mainFont;

    > main#page-content
    {
        flex-grow: 1;
        display: flex;
        align-self: stretch;
        flex-direction: column;
        justify-content: center;
        max-width: 100%;

        @include media-breakpoint-down(sm)
        {
            padding: 0 1rem;
        }
    }
}

main#page-content > article .ck-content:first-child
{
    margin-top: 2rem;
}

.btn-secondary
{
    @include button-variant($secondary, $colorBaseMid);
}