@include media-breakpoint-down(sm)
{
    h3
    {
        font-size: 1.333rem;
    }
}

h4
{
    font-weight: bold;
}

main
{
    section, aside
    {
        margin: $sectionPadding 0;

        @include media-breakpoint-only(xs)
        {
            margin: #{($sectionPadding / 2)} 0;
        }
    }
}

summary
{
    display: block;

    &::-webkit-details-marker
    {
        display: none;
    }
}

p
{
    @extend %base-text-size;

    line-height: 2rem;
    margin-bottom: 1.2rem;

    &:last-child
    {
        margin-bottom: 0;
    }
}

.font-alternate
{
    font-family: $altFont;
}
