.btn-feedback
{
    position: fixed;
    top: 40%;
    right: 0;
    transform: rotate(-90deg) translateX(50%) translateY(.667rem);
    transform-origin: bottom right;
    background-color: $colorAccent;
    color: $colorInverse;
    text-decoration: none;
    padding: .5rem 1rem 1rem;
    line-height: 1;
    box-shadow: 0 0 .25rem rgba(#000, 0.33);
    font-weight: 500;
    transition: transform linear .1667s;
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;

    &:hover
    {
        color: $colorInverse;
        transform: rotate(-90deg) translateX(50%);
    }
}
