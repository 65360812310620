%base-text-size
{
    font-size: 1.125rem;

    @include media-breakpoint-down(sm)
    {
        font-size: 1rem;
    }
}

%sel-form
{
    label
    {
        @extend %base-text-size;
        font-weight: bold;
        color: $colorAccent;
    }

    .select-wrapper
    {
        position: relative;

        &:after
        {
            content: "";
            position: absolute;
            width: 3rem;
            height: 100%;
            right: 0;
            top: 0;
            border-left: 1px solid $colorAccent;
            z-index: 10;
            pointer-events: none;
        }
    }

    .form-select
    {
        padding-top: .5rem;
        padding-bottom: .5rem;
        background-size: 24px 18px;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23023d5b' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    }

    .form-control,
    .col-btn .btn
    {
        padding-top: .5rem;
        padding-bottom: .5rem;
    }

    @include media-breakpoint-down(md)
    {
        .col-md:not(:last-child)
        {
            margin-bottom: 1rem;
        }
    }

    .col-btn
    {
        display: flex;
        flex-direction: column;

        @include media-breakpoint-up(md)
        {
            padding-top: 2.175rem;
        }

        .btn
        {
            color: $colorAlternate;
            font-weight: bold;
        }
    }
}

%contained-item
{
    max-width: 58rem;
    margin-left: auto;
    margin-right: auto;
}
