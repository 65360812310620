@import "../variables";

$primary: $colorAccent;
$secondary: $colorAlternate;

$body-color: $colorBaseMid;

$font-weight-normal: normal;
$font-family-sans-serif: $mainFont;
$headings-font-family: $altFont;
$headings-font-weight: 700;
$small-font-size: #{(2rem / 3)};

$grid-gutter-width: 2rem;
$grid-breakpoints: ( xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px );
$container-max-widths: ( sm: 540px, md: 720px, lg: 960px, xl: 1140px );

$enable-rounded: false;
$input-color: $primary;
$input-border-color: $primary;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/utilities";