/* montserrat-300 - latin */
@font-face
{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-display: fallback;
    src: local(''), url('montserrat-v23-latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('montserrat-v23-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-regular - latin */
@font-face
{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-display: fallback;
    src: local(''), url('montserrat-v23-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('montserrat-v23-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-500 - latin */
@font-face
{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-display: fallback;
    src: local(''), url('montserrat-v23-latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('montserrat-v23-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-700 - latin */
@font-face
{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-display: fallback;
    src: local(''), url('montserrat-v23-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('montserrat-v23-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-italic - latin */
@font-face
{
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 400;
    font-display: fallback;
    src: local(''), url('montserrat-v23-latin-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('montserrat-v23-latin-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-700italic - latin */
@font-face
{
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 700;
    font-display: fallback;
    src: local(''), url('montserrat-v23-latin-700italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('montserrat-v23-latin-700italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
