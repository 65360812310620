header#page-header
{
    padding: 0 0 1rem;

    h1
    {
        color: $colorInverse;
        font-size: 2.25rem;

        @include media-breakpoint-down(sm)
        {
            margin: 1.5rem 0;
        }

        @include media-breakpoint-down(md)
        {
            font-size: 2rem;
        }

        a
        {
            color: $colorInverse;
        }
    }

    > .bg-primary
    {
        background-color: $colorAccent;
    }

    a
    {
        text-decoration: none;
    }
}

#header-logo
{
    margin: 30px 0;

    @include media-breakpoint-down(sm)
    {
        margin: 20px 0;
    }
}


#sel-mobile-logo
{
    height: 60px;
    margin-left: 1rem;
}

#sel-logo
{
    margin: 20px 0;

    @include media-breakpoint-down(sm)
    {
        margin-bottom: 1.5rem;
    }

    @include media-breakpoint-down(md)
    {
        height: 64px;
    }
}

nav#page-nav
{
    margin-top: 1rem;
}

#ie-banner
{
    background-color: #c00;
    color: #fff;
    display: none;
    padding: 1rem 2rem;
    text-align: center;

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)
    {
        display: block;
    }
}
