footer#page-footer
{
    margin-top: 3rem;
    padding: 3rem 0;
    background-color: $colorAccent;
    color: $colorInverse;

    .container
    {
        display: grid;
        grid-template: "addr btt" "menu logo" / 3fr 2fr;
        gap: 2rem;

        @include media-breakpoint-down(md)
        {
            gap: 2rem 1.5rem;
        }

        @include media-breakpoint-down(sm)
        {
            grid-template: "addr btt" "logo logo" "menu menu" / 3fr 2fr;
        }
    }

    .footer-address
    {
        grid-area: addr;

        > p:last-child
        {
            margin-bottom: 0;
        }
    }

    .footer-backtotop
    {
        grid-area: btt;
        justify-self: end;

        @include media-breakpoint-down(md)
        {
            align-self: center;
        }
    }

    .footer-menu
    {
        grid-area: menu;
        align-self: end;

        @include media-breakpoint-down(sm)
        {
            text-align: center;
        }

        @include media-breakpoint-down(md)
        {
            align-self: center;
        }
    }

    .footer-logo
    {
        grid-area: logo;
        justify-self: end;
        align-self: end;

        @include media-breakpoint-down(md)
        {
            justify-self: center;

            img
            {
                height: 64px;
            }
        }
    }

    p
    {
        line-height: 1.25;
        margin-bottom: 1.5rem;
        font-weight: 500;
    }

    a
    {
        color: $colorInverse;
    }

    .list-inline
    {
        @extend %base-text-size;
        margin-bottom: 0;

        > .list-inline-item:not(:last-child)
        {
            margin-right: 1rem;
        }
    }
}


a#back-to-top
{
    text-decoration: none;
    font-weight: bold;
    font-size: 1rem;
    margin-bottom: 3.125rem;
    display: inline-block;

    > img
    {
        display: block;
        margin: 0 auto .5rem;
    }

    @include media-breakpoint-down(md)
    {
        margin-bottom: 0;
    }
}
